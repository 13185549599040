import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SideBar from './Components/SideBar/Sidebar';
import Home from './Pages/Home/Home';
import Order from './Pages/Order/Order';
import Category from './Pages/Category/Category';
import Products from './Pages/Products/Products';
import Type from './Pages/Type/Type';
import Brand from './Pages/Brand/Brand';
import { Login } from '@mui/icons-material';
import LoginPage from './Pages/Login/Login';
import Slider from './Pages/Slider/Slider';

function App() {
  return (
    <>
      <Router>
        <SideBar />
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={<Home />} />
          <Route path='/order' element={<Order />} />
          <Route path='/category' element={<Category />} />
          <Route path='/product' element={<Products />} />
          <Route path='/type' element={<Type />} />
          <Route path='/brand' element={<Brand />} />
          <Route path='/slider' element={<Slider />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;