import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import axios from 'axios';
import DeleteAskModal from '../Modal/Ask/DeleteAskModal';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import EditCategoryModal from '../Modal/Category/EditCategoryModal';

export default function CategoryTable({ categories, types }) {
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [category, setCategory] = useState({});
    const [id, setId] = useState('');

    const closeModal = () => {
        setIsDelete(false);
        setIsEdit(false)
    }

    const onDelete = () => {
        axios.delete('https://erhem.templateapi.xyz/categories/' + id)
            .then((e) => window.location.reload(false))
            .catch((e) => console.log(e))
    }

    return (
        <>{isEdit && id && category ? <EditCategoryModal closeModal={closeModal} id={id} types={types} category={category} />
            : isDelete && id ? <DeleteAskModal closeModal={closeModal} ondelete={onDelete} /> :
                <TableContainer sx={{ width: '80vw' }} component={Paper}>
                    <Table sx={{ width: '80vw' }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ background: '#FBD7D7' }}>
                                <TableCell>№</TableCell>
                                <TableCell align='center'>Нэр</TableCell>
                                <TableCell align="center">Үүсгэсэн огноо</TableCell>
                                <TableCell align="center">Үйлдэл</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((row, index) => (
                                <TableRow
                                    className='hover'
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell component="th" scope="row" align='center'>
                                        {row.cat_name}
                                    </TableCell>
                                    <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                                    <TableCell align="center" className='gap-15'>
                                        <MdDeleteOutline size={25} color='red' className='hover-icon'
                                            onClick={() => {
                                                setId(row._id);
                                                setIsDelete(true)
                                            }} />
                                        <MdModeEditOutline size={25} color='blue' className='hover-icon'
                                            onClick={() => {
                                                setId(row._id);
                                                setIsEdit(true)
                                                setCategory(row)
                                            }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        }</>
    );
}
