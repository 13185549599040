import axios from 'axios';
import React, { useEffect, useState } from 'react'

const SelectUserOrderModal = ({ closeModal, order }) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState('');

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/user')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
        }
    }, [isLoading])

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', order.name);
        formData.append('state', order.state);
        formData.append('steet', order.steet);
        formData.append('number', order.number);
        formData.append('type', order.type);
        formData.append('description', order.description);
        formData.append('message', order.message);
        formData.append('status', order.status);
        formData.append('user', user);
        // formData.append('items', order.items);
        try {
            // https://erhem.templateapi.xyz
            const response = await axios.put('https://erhem.templateapi.xyz/order/id/' + order._id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                window.location.reload(false);
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <>{
            isLoading ? <div><p></p></div> :
                <div className='blur_container'>
                    <div className="modal">
                        <div className="two-line">
                            <h2>Харилцагч сонгох</h2>
                            <h2 style={{ cursor: 'pointer' }} onClick={closeModal} >
                                X
                            </h2>
                        </div>
                        <select onChange={(e) => setUser(e.target.value)}>
                            <option value=''>сонгох</option>
                            {
                                users.map((e) => (
                                    <option value={e._id}>{e.name}</option>
                                ))
                            }
                        </select>
                        <div className="two-line">
                            <button onClick={handleSubmit} style={{ color: 'green' }}>Хадгалах</button>
                            <button onClick={closeModal} style={{ color: 'red' }}>Цуцлах</button>
                        </div>
                    </div>
                </div>
        }</>
    )
}
export default SelectUserOrderModal
