import React, { useEffect, useState } from 'react'
import styles from './Home.module.css'
import UserAddModal from '../../Modal/User/UserModal'
import UserTable from '../../Table/UserTable';
import axios from 'axios';

const Home = () => {
    const [isModal, setIsModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/user')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>{isModal ?
            <UserAddModal closeModal={closeModal} /> :
            <div className="container">
                <div className='row'>
                    <div className={styles.d_card}>
                        <p>
                            Нийт төрлүүд
                        </p>
                        <div className="divider" style={{ width: '200px' }}></div>
                        <h3>10</h3>
                    </div>
                    <div className={styles.d_card}>
                        <p>
                            Нийт Категори
                        </p>
                        <div className="divider" style={{ width: '200px' }}></div>
                        <h3>10</h3>
                    </div>
                    <div className={styles.d_card}>
                        <p>
                            Нийт Бараа
                        </p>
                        <div className="divider" style={{ width: '200px' }}></div>
                        <h3>10</h3>
                    </div>
                    <div className={styles.d_card}>
                        <p>
                            Нийт Захиалга
                        </p>
                        <div className="divider" style={{ width: '200px' }}></div>
                        <h3>10</h3>
                    </div>
                </div>
                <div>
                    <button onClick={() => setIsModal(true)}>Харилцагч нэмэх</button>
                    <br />
                    <br />
                    <UserTable users={users} />
                </div>
            </div>
        }</>
    )
}

export default Home
