import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function OrderItemsTable({ products, items }) {
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const closeModal = () => {
        setIsDelete(false);
        setIsEdit(false);
    }

    return (
        <>
            <TableContainer sx={{ width: '40vw' }} component={Paper}>
                <Table sx={{ width: '40vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: '#FBD7D7' }}>
                            <TableCell>№</TableCell>
                            <TableCell>Зураг</TableCell>
                            <TableCell>Нэр</TableCell>
                            <TableCell>Тоо ширхэг</TableCell>
                            <TableCell align="center">Үнэ</TableCell>
                            {/* <TableCell align="center">Бренд</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => (
                            <TableRow
                                className='hover'
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell component="th" scope="row">
                                    <img src={"https://erhem.templateapi.xyz/" + products.find((e) => e._id === row._id)?.productImages[0]} style={{ width: '100px', height: '100px' }} alt="" />
                                </TableCell>
                                <TableCell align="center">
                                    {products.find((e) => e._id === row._id)?.productName}
                                </TableCell>
                                <TableCell align="center">1</TableCell>
                                <TableCell align="center">{products.find((e) => e._id === row._id)?.price}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    );
}
