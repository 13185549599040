import React, { useState } from 'react'
import styles from './Sidebar.module.css'
import { MdUnfoldMore } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineShopping } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { PiNewspaperClipping } from "react-icons/pi";
import { MdTravelExplore } from "react-icons/md";
import { Link } from 'react-router-dom';
import { SiBrandfolder } from "react-icons/si";
import { TfiLayoutSlider } from "react-icons/tfi";

const Sidebar = () => {
    const [link, setLink] = useState(0);

    return (
        <div className={styles.container}>
            <div className={styles.user_card}>
                <img className={styles.profile} src="https://media.istockphoto.com/id/5/photo/shot-of-a-handsome-young-man-standing-against-a-grey-background.jpg?s=612x612&w=0&k=20&c=JSBpwVFm8vz23PZ44Rjn728NwmMtBa_DYL7qxrEWr38=" alt="" />
                <div className='column'>
                    <p className='mini-bold-text'>Thomas Brown</p>
                    <p className='mini-grey-text'>thomas@gmail.com</p>
                </div>
                <MdUnfoldMore size={25} color='#333' />
            </div>
            <div className={styles.items}>
                <Link to={'/dashboard'} onClick={() => setLink(0)}
                    className={link === 0 ? styles.item : styles.unSelectItem}
                >
                    <AiOutlineHome size={25} />
                    Хянах самбар
                </Link>
                <Link to={'/order'} onClick={() => setLink(1)}
                    className={link === 1 ? styles.item : styles.unSelectItem}>
                    <AiOutlineShopping size={25} />
                    Захиалгууд
                </Link>
                <Link to={'/type'} onClick={() => setLink(2)}
                    className={link === 2 ? styles.item : styles.unSelectItem}>
                    <BiBuildingHouse size={25} />
                    Төрөл
                </Link>
                <Link to={'/category'} onClick={() => setLink(3)}
                    className={link === 3 ? styles.item : styles.unSelectItem}>
                    <PiNewspaperClipping size={25} />
                    Категори
                </Link>
                <Link to={'/product'} onClick={() => setLink(4)}
                    className={link === 4 ? styles.item : styles.unSelectItem}>
                    <MdTravelExplore size={25} />
                    Бараанууд
                </Link>
                <Link to={'/brand'} onClick={() => setLink(5)}
                    className={link === 5 ? styles.item : styles.unSelectItem}>
                    <SiBrandfolder size={25} />
                    Бренд
                </Link>
                <Link to={'/slider'} onClick={() => setLink(6)}
                    className={link === 6 ? styles.item : styles.unSelectItem}>
                    <TfiLayoutSlider size={25} />
                    Нүүр хуудас зураг
                </Link>
            </div>
        </div>
    )
}

export default Sidebar