import React from 'react'

const DeleteAskModal = ({ closeModal, ondelete }) => {
    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Утсгах</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <p>Та устгахдаа итгэлтэй байна уу?</p>
                <div className="two-line">
                    <button onClick={ondelete}>Утсгах</button>
                    <button onClick={closeModal}>Цуцлах</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteAskModal
