import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaDeleteLeft } from "react-icons/fa6";
import { MdLibraryAdd } from "react-icons/md";

const AddProductModal = ({ closeModal }) => {
    const [productName, setProductName] = useState('');
    const [productImages, setProductImages] = useState([]);
    const [inputs, setInputs] = useState([{ size: "", price: 0 }]);
    const [price, setPrice] = useState(0);
    const [oldprice, setOldPrice] = useState(0);
    const [brand, setBrand] = useState('');
    const [discount, setDiscount] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [warning, setWarning] = useState('');
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/categories')
                .then((e) => {
                    setCategories(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
            axios.get('https://erhem.templateapi.xyz/brand')
                .then((e) => {
                    setBrands(e.data.data);
                })
                .catch((e) => console.log(e))
        }
    }, [isLoading])

    const handleAddInput = () => {
        setInputs([...inputs, { size: "", price: 0 }]);
    };

    const handleChange = (event, index) => {
        let { name, value } = event.target;
        let onChangeValue = [...inputs];
        onChangeValue[index][name] = value;
        setInputs(onChangeValue);
    };

    const handleDeleteInput = (index) => {
        const newArray = [...inputs];
        newArray.splice(index, 1);
        setInputs(newArray);
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setProductImages(files);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append('productName', productName);
        formData.append('category', category);
        formData.append('price', price);
        formData.append('oldPrice', oldprice);
        formData.append('discount', discount);
        formData.append('description', description);
        formData.append('brand', brand);
        formData.append('warning', warning);
        // formData.append('weight', inputs);
        formData.append('weight', JSON.stringify(inputs));
        productImages.forEach((image, index) => {
            formData.append(`image${index}`, image); // Change to match the backend field name
        });

        try {
            const response = await axios.post('https://erhem.templateapi.xyz/product', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => window.location.reload(false));
            // console.log(response.data);
            // setProductName('');
            // setProductImages([]);
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <>{isLoading ? <div></div> :
            <div className='blur_container'>
                <div className="modal">
                    <div className="two-line">
                        <h2>Бараа нэмэх</h2>
                        <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                    </div>

                    <label>
                        Нэр:
                        <input onChange={(e) => setProductName(e.target.value)} type="text" name="" id="" />
                    </label>

                    <label>
                        Ангилал:
                        <select onChange={(e) => setCategory(e.target.value)}>
                            <option value="">Сонгох</option>
                            {categories.map((e) => (
                                <option value={e._id}>{e.cat_name}</option>
                            ))}
                        </select>
                    </label>

                    {/* <label>
                        Үнэ:
                        <input onChange={(e) => setPrice(e.target.value)} type="number" name="" id="" />
                    </label>

                    <label>
                        Өмнөх үнэ:
                        <input onChange={(e) => setOldPrice(e.target.value)} type="number" name="" id="" />
                    </label> */}

                    <label>
                        Хямдрал:
                        <input onChange={(e) => setDiscount(e.target.value)} type="number" name="" id="" />
                    </label>

                    <label>
                        Анхааруулга:
                        <input onChange={(e) => setWarning(e.target.value)} type="text" name="" id="" />
                    </label>

                    <label>
                        Бренд:
                        <select onChange={(e) => setBrand(e.target.value)}>
                            <option value="">Сонгох</option>
                            {brands.map((e) => (
                                <option value={e._id}>{e.name}</option>
                            ))}
                        </select>
                    </label>

                    <label>
                        Тайлбар:
                        {/* <input type="number" name="" id="" /> */}
                        <textarea name="" id="" cols="30"
                            onChange={(e) => setDescription(e.target.value)} rows="10"></textarea>
                    </label>

                    <label>
                        Хэмжээ:
                        {/* <button onClick={() => handleAddInput()}>Add</button> */}
                        <MdLibraryAdd onClick={() => handleAddInput()}
                            size={30} color='green' cursor={'pointer'} />

                    </label>

                    <div className='column'>
                        {inputs.map((item, index) => (
                            <div className="grid-3" key={index}>
                                <input
                                    name="size"
                                    type="text"
                                    placeholder='Хэмжээ'
                                    value={item.size}
                                    onChange={(event) => handleChange(event, index)}
                                />
                                <input
                                    name="price"
                                    type="number"
                                    placeholder='Үнэ'
                                    value={item.price}
                                    onChange={(event) => handleChange(event, index)}
                                />
                                {inputs.length > 1 && (
                                    // <button onClick={() => handleDeleteInput(index)}>Delete</button>
                                    <FaDeleteLeft onClick={() => handleDeleteInput(index)}
                                        size={30} cursor={'pointer'} color='red' />
                                )}
                            </div>
                        ))}
                    </div>
                    <input type="file" multiple onChange={handleImageChange} />
                    <div className="grid-5">
                        {productImages.map((image, index) => (
                            <img key={index} src={URL.createObjectURL(image)} alt={`Product Image ${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                        ))}
                    </div>
                    <div className="two-line">
                        <button onClick={handleSubmit} style={{ color: 'green' }}>Хадгалах</button>
                        <button onClick={closeModal} style={{ color: 'red' }}>Цуцлах</button>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default AddProductModal;